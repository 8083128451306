<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div v-if="category != null" class="page__title">{{ category.title }}</div>
                <div class="page__desc">Список товаров в категории</div>
            </div>
            <div class="page__actions">
                <router-link
                        :to="{ name: 'CatalogProductCreate', params: { category_id: this.$route.params.id } }">
                    <button type="button" class="btn btn-success">Добавить</button>
                </router-link>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Название</th>
                        <th>Действие</th>
                    </tr>
                    <draggable v-model="products" draggable="tr" tag="tbody">
                        <tr v-for="(product, index) in products"
                            v-bind:key="product.id"
                            style="cursor: move;">
                            <td>{{ product.title }}</td>
                            <td>
                                <router-link
                                        :to="{ name: 'CatalogProductUpdate', params: {id: product.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        value="Изменить"/>
                                <input
                                        @click="deleteProduct(index)"
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        value="Удалить">
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import draggable from 'vuedraggable'
    import * as types from '@/store/mutation-types'

    export default {
        name: 'CatalogCategoriesProducts',
        components: {
            draggable,
        },
        computed: {
            category() {
                return this.$store.state.catalog.category
            },
            products: {
                get() {
                    return this.$store.state.catalog.products
                },
                set(value) {
                    let data = [];
                    for (let i=0; i<value.length; i++) {
                        data.push({id: value[i].id, sort: i});
                    }
                    this.sortProducts(data);

                    this.$store.commit(types.FILL_CATALOG_PRODUCTS, value)
                }
            }
        },
        methods: {
            ...mapActions([
                'catalogCategoriesProducts',
                'catalogCategoriesProductsSort',
                'catalogProductDelete',
                'catalogCategoryGet',
            ]),
            async getCategory() {
                await this.catalogCategoryGet({
                    id: this.$route.params.id,
                })
            },
            async getProducts() {
                await this.catalogCategoriesProducts({
                    id: this.$route.params.id,
                })
            },
            async sortProducts(data) {
                await this.catalogCategoriesProductsSort({
                    id: this.$route.params.id,
                    data: data,
                })
            },
            async deleteProduct(index) {
                if (confirm('Вы уверены, что хотите удалить?')) {
                    let category = this.categories.splice(index, 1)[0];

                    await this.catalogProductDelete({
                        id: category.id
                    })
                }
            }
        },
        created() {
            this.getCategory();
            this.getProducts();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>